.about_container {
    display: grid;
    grid-template-columns: 22% 68%;
    gap: 15%;
}

.about__me {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: flex;
    align-items: center;
    justify-content: center;
}

.about__me-image {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__me-image img {
    width: 100%;
    height: 100%;
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 0.5rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card ul {
    font-size: 1rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    font-size: large;
    color: var(--color-light);
}

.aboutme {
    text-align: center;
    color: var(--color-primary);
    font-size: x-large;
}

.sabout {
    font-size: large;
    text-align: center;
    color: var(--color-white);
}

b {
    color: var(--color-white);
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

/*========== MEDIA QUERIES ( MEDIUM DEVICES ) ==============*/

@media only screen and (max-width: 1024px) {
    .about_container {
        grid-template-columns: 100%; /* Full width for mobile */
        gap: 0; /* Remove gap on mobile */
    }
    
    .about__cards {
        grid-template-columns: 100%; /* Full width for mobile */
        gap: 1rem; /* Adjust gap for mobile */
    }
    .about__content {
        margin-top: 2rem;
    }
    .about__me-image {
        transform: rotate(0deg);
    }
    
 }

/*========== MEDIA QUERIES ( SMALL DEVICES ) ==============*/

 @media only screen and (max-width: 600px) {
    .about_container {
        grid-template-columns: 100%; /* Full width for mobile */
        gap: 0; /* Remove gap on mobile */
    }
    
    .about__cards {
        grid-template-columns: 100%; /* Full width for mobile */
        gap: 1rem; /* Adjust gap for mobile */
    }
    .about__content {
        margin-top: 2rem;
    }
    .about__me-image {
        transform: rotate(0deg);
    }
    
 }