header{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.header_text{
    top: 30%;
    text-align: center;
    position: relative;
}

/*========== CTA ============*/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/*========== HEADER SOCIALS ============*/

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*========== ME IMAGE ============*/

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.me img {
    width: 100%;
    height: 120%;
    object-fit: contain;
}


/*========== SCROLL DOWN ===========*/

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*=========== THEME TOGGLE ===========*/

.nav-toggle {
    position: absolute;
    right: -3.2rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    cursor: pointer;
}


/*========== MEDIA QUERIES ( MEDIUM DEVICES ) ==============*/

@media screen and (max-width: 1024px) {
    header {
        height: auto; /* Adjust the header height as per your design */
    }
    .me {
        display: none; /* Hide the "About Me" section on mobile */
    }
    .header__socials {
        flex-direction: row; /* Show social icons horizontally */
        justify-content: center; /* Center the social icons horizontally */
        position: static; /* Remove absolute positioning */
        margin-top: 2.5rem; /* Adjust the margin as per your design */
    }
    .header__socials::after {
        display: none; /* Hide the vertical line separator */
    }
    .scroll__down {
        display: none; /* Hide the scroll down indicator */
    }
    .cta {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .nav-toggle {
        display: none;
    }
}

/*========== MEDIA QUERIES ( SMALL DEVICES ) ==============*/
     
@media screen and (max-width: 600px) {
            header {
                height: auto; /* Adjust the header height as per your design */
            }
            .me {
                display: none; /* Hide the "About Me" section on mobile */
            }
            .header__socials {
                flex-direction: row; /* Show social icons horizontally */
                justify-content: center; /* Center the social icons horizontally */
                position: static; /* Remove absolute positioning */
                margin-top: 2.5rem; /* Adjust the margin as per your design */
            }
            .header__socials::after {
                display: none; /* Hide the vertical line separator */
            }
            .scroll__down {
                display: none; /* Hide the scroll down indicator */
            }
            .cta {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .nav-toggle {
                display: none;
            }
 }