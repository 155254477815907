.nav__items {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

.nav__items a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

.nav__items a:hover{
    background: rgba(0, 0, 0, 0.3);
}

.nav__items a.active {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

/* Initially hidden */
.nav__items.hidden {
  opacity: 0;
  pointer-events: none; /* To prevent interaction when hidden */
  transition: opacity 0.5s ease; /* Adjust the transition duration as needed */
}
  
  /* Visible */
  .nav__items.visible {
    opacity: 1;
    pointer-events: auto; /* Enable interaction when visible */
    transition: opacity 0.5s ease; /* Adjust the transition duration as needed */
  }